/*------------------------COLORES 
------------------------PRINCIPAL: #47B8C2 ------------------------
------------------------BLANCO: #EEEEEE // #F5F5F5 ------------------------
------------------------NEGRO: #1A1A1A ------------------------
------------------------GRIS: #C4C4C4
------------------------------------------------------------------------*/

:root {
  --principal: #47B8C2;
  --blanco1: #EEEEEE;
  --blanco2: #F5F5F5;
  --negro: #333333;
  --gris: #dadada;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-weight: 400;
  color: var(--negro);

}

strong {
  font-weight: 600;
  color: var(--negro);
}


body {
  background: var(--blanco1) !important;
  background: linear-gradient(130deg, rgb(233, 240, 245) 0%, rgb(242, 240, 244) 50%, rgb(245, 245, 245) 100%) !important;
}

.fuente {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal !important;
}

.fondo-azul {
  /* background-color: #47B8C2 !important; */
  background: rgb(71, 184, 194);
  background: linear-gradient(155deg, rgba(71, 184, 194, 1) 0%, rgba(106, 198, 207, 1) 50%, rgba(115, 204, 213, 1) 100%);
}

.fondo-blanco {
  background: rgb(233, 240, 245);
  background: linear-gradient(130deg, rgba(233, 240, 245, 1) 0%, rgba(242, 240, 244, 1) 50%, rgba(245, 245, 245, 1) 100%);
}

.fondo-negro {
  background-color: var(--negro) !important;
}

.fondo-gris {
  background-color: var(--gris) !important;
}

.sombra-corta {
  box-shadow: rgb(0, 0, 0, 0.15) 2px 1px 5px 0px;
}

.sombra-larga {
  box-shadow: rgb(0, 0, 0, 0.15) 2px 1px 10px 0px;
}

.bordes-redondos {
  border-radius: 5px;
}

.no-margen {
  margin: 0em !important;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pointer {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.h-destacado {
  color: #1A1A1A;
  transition: color 0, 5s;
}

.h-destacado:hover {
  color: #C4C4C4;
  transition: color 0, 5s;
}

.blur {
  filter: blur(00.00px);
}

.drop {
  filter: drop-shadow(3px 2px 5px rgba(255, 255, 255, 0.9));
}





.header {
  position: fixed;
  top: 1;
  left: 1;
  z-index: 100;
}

/* .pie-pagina{
  background-color: black;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 7vh;
  color: white;
} */

.boton-principal {
  background-color: var(--principal);
  color: #FFF;
  /* font-weight: 600; */
  padding: 1em;
  border-radius: 5px;
  box-shadow: rgb(0, 0, 0, 0.15) 4px 2px 10px 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: box-shadow 0.5s, border-radius 0.5s, background-color 0.5s, color 0.5s, filter 0.15s;
}

.boton-principal:hover {
  transition: box-shadow 0.5s, border-radius 0.5s, background-color 0.5s, color 0.5s, filter 0.15s;
  /* border-radius: 10px; */
  /* background-color: #FFF; */
  color: #EEEEEE;
  filter: brightness(105%);
  /* color: #E8E8E8 !important; */
  box-shadow: rgb(0, 0, 0, 0.15) 5px 2px 5px 3px;

}

.boton-secundario {
  background-color: #1A1A1A00;
  color: var(--principal);
  /* font-weight: 600; */
  padding: 1em;
  border-radius: 5px;
  border: var(--principal) 1px solid;
  box-shadow: rgb(0, 0, 0, 0.15) 4px 2px 10px 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: box-shadow 0.5s, border-radius 0.5s, background-color 0.5s, color 0.5s, filter 0.15s;
}


.boton-header {
  padding: 0.6em 1em;
  border-radius: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: box-shadow 0.5s, border-radius 0.5s, background-color 0.5s, color 0.5s, filter 0.15s;
}

.boton-header:hover {
  transition: box-shadow cubic-bezier(0.4, 0, 0.2, 1) 0.5s, border-radius cubic-bezier(0.4, 0, 0.2, 1) 0.5s, background-color cubic-bezier(0.4, 0, 0.2, 1) 0.5s, color cubic-bezier(0.4, 0, 0.2, 1) 0.5s, filter cubic-bezier(0.4, 0, 0.2, 1) 0.15s;
  background-color: #FFF;
  filter: brightness(105%);
  box-shadow: rgb(0, 0, 0, 0.15) 2px 1px 10px 0px;


}

.categoria-card {
  position: relative;
  padding: 0em 0.5em;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  color: #1A1A1A;
  background-size: cover;
  min-height: 25vh;
  overflow: hidden;
  /* Asegura que el pseudo-elemento no se salga del contenedor */
}

.categoria-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* Color inicial */
  z-index: 1;
  transition: background-color 0.35s ease;
  border-radius: 5px;
}

.categoria-card:hover::before {
  background-color: #ffffff60;
  /* Color al hacer hover */
}

.categoria-card>* {
  position: relative;
  z-index: 2;
  /* Eleva el contenido por encima del pseudo-elemento */
}

.producto-card {
  background-color: #EEEEEE;
  border-radius: 5px;
  cursor: pointer;
  padding: 1em;
  /* border: 1px solid #1A1A1A; */
  transition: box-shadow 0.5s ease, padding 0.2s ease, filter 0.25s ease;
  text-align: center;
}

.producto-card:hover {
  /* padding: 1.5em; */
  filter: brightness(102%);
  box-shadow: rgb(0, 0, 0, 0.15) 2px 2px 4px 2px;

  transition: box-shadow 0.5s ease, padding 0.2s ease, filter 0.25s ease;
}

.cesta-card {
  margin-top: 3em;
  padding: 0.5em 1em;
  text-align: center;
}

.lista-cesta {
  text-align: left;
  /* overflow: auto;
  max-height: 50vh;
  min-height: 50vh; */
}

.separador {
  min-width: 100% !important;
  min-height: 0vh;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border: solid 0.1em var(--gris);
}

.separador-2 {
  /* min-width: 100% !important; */
  min-height: 0vh;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border: solid 0.1em var(--gris);
}

.separador-3 {
  min-width: 100% !important;
  min-height: 0vh;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border: solid 0.1em var(--principal);
}

.aprt-usuario {
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.5s;
  /* Corregido el valor de la transición */
}

.aprt-usuario:hover {
  transition: background-color 0.5s;
  /* Corregido el valor de la transición */
  background-color: #F5F5F5;
}

.cabecera-filtros {
  border-radius: 5px;
}

.finalizar-pedido {
  overflow: initial !important;

}

.buscador {
  border-radius: 5px !important;
  min-width: 100% !important;
  background-color: var(--blanco1) !important;
  transition: background-color 0.5s !important;
}

.buscador:hover,
.buscador:focus-within {
  transition: background-color 0.5s !important;
  background-color: var(--blanco2) !important;

}

.login {
  border-radius: 10px;
}

/* .contenido-filtros{
  border-bottom-left-radius: 50px !important;
} */






/* --------------------------------ANIMACIONES------------------------------------------- */

@keyframes load {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.load {
  animation: load 0.35s linear;
}














/* ------------------------------------------------------------------------------------------ */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}